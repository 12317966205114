import { classNames } from "common/functions";

import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "components/common/ErrorFallback";

/**
 *
 * @param {{
 * variant: "light" | "outline";
 * }} props Props for the component
 *
 */
const className = (variant, error) => {
  let defaultClass =
    "block w-full transition-all duration-200 outline-0 focus:ring-0 focus:outline-0 placeholder:text-arrivalhub-greys-400";
  let variantClass = "";
  switch (variant) {
    case "light":
      variantClass = classNames(
        `rounded-md bg-white text-arrivalhub-greys-600 px-4 py-3 text-sm border border-arrivalhub-gray-500 ring-0 `,
        `focus:ring-0 focus:border-arrivalhub-oxfordBlue-500`,
        `invalid:ring-0 invalid:border-b invalid:border-arrivalhub-danger-500 ${
          error && "invalid:text-arrivalhub-danger-500"
        }`
      );
      break;
    case "outline":
      variantClass = classNames(
        `bg-transparent text-arrivalhub-greys-600 px-0 py-3 text-sm border-transparent border-b border-b-arrivalhub-basic`,
        `focus:ring-0 focus:border-transparent focus:border-b focus:border-b-arrivalhub-oxfordBlue-500`,
        `invalid:ring-0 invalid:border-transparent invalid:border-b invalid:border-b-arrivalhub-danger-500 ${
          error && "invalid:text-arrivalhub-danger-500"
        }`
      );
      break;
    default:
      variantClass = classNames(
        `rounded-md bg-white text-arrivalhub-greys-600 px-4 py-3 text-sm border-transparent ring-0 `,
        `focus:ring-0 focus:border-arrivalhub-oxfordBlue-500`,
        `invalid:ring-0 invalid:border-b invalid:border-arrivalhub-danger-500 ${
          error && "invalid:text-arrivalhub-danger-500"
        }`
      );
      break;
  }
  return defaultClass + " " + variantClass;
};

/**
 *
 * @param {{
 * variant: "light" | "outline";
 * }} props Props for the component
 *
 */
export const InputEmail = ({
  name,
  placeholder,
  register,
  errors,
  requiredText,
  variant,
  othersClass,
  validCondition,
  name_label,
  ...props
}) => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <div className={othersClass}>
        <label className="mb-1 inline-block text-arrivalhub-gray-500">
          {name_label}
        </label>
        <input
          type="email"
          {...register(
            name ? name : "email",
            validCondition
              ? validCondition
              : {
                  required: {
                    value: true,
                    message: requiredText || "Email là bắt buộc",
                  },
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    message: "Email format is wrong",
                  },
                }
          )}
          placeholder={placeholder || "Email"}
          className={classNames(
            className(variant, errors[name] ? errors[name] : "")
          )}
          autoComplete="off"
          {...props}
        />
        {errors[name] && (
          <span className="inline-block pt-2 text-left text-sm text-arrivalhub-danger-500">
            {name ? errors[name]?.message : errors.email?.message}
          </span>
        )}
      </div>
    </ErrorBoundary>
  );
};
// Text Field must be single word or multiple word space with "_"
/**
 *
 * @param {{
 * variant: "light" | "outline";
 * patternType:"text"|"website";
 * }} props Props for the component
 *
 */
export const InputText = ({
  as,
  name,
  placeholder,
  register,
  errors,
  variant,
  othersClass,
  requiredText,
  patternType,
  validCondition,
  name_label,
  ...props
}) => {
  const CustomTag = as || "input";
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <div className={othersClass}>
        <label className="mb-1 inline-block text-arrivalhub-gray-500">
          {name_label}
        </label>
        <CustomTag
          type="text"
          {...register(
            name,
            validCondition
              ? {
                  ...validCondition,
                  ...(patternType === "website" && {
                    pattern: {
                      value:
                        /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._+~#=]{2,256}(.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*))*(.com|.vn|.net|.org|.int|.edu|.gov|.mil)/gi,
                      // /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi
                      message: "Định dạng website sai",
                    },
                  }),
                }
              : {
                  required: {
                    value: true,
                    message: requiredText || "This field is required",
                  },
                  ...(patternType === "website" && {
                    pattern: {
                      value:
                        /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._+~#=]{2,256}(.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*))*(.com|.vn|.net|.org|.int|.edu|.gov|.mil)/gi,
                      // /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi
                      message: "Định dạng website sai",
                    },
                  }),
                }
          )}
          placeholder={placeholder || "Text"}
          className={classNames(
            className(variant, errors[name] ? errors[name] : "")
          )}
          pattern={
            patternType === "website"
              ? "[(http(s)?):\\/\\/(www\\.)?a-zA-Z0-9@:%._+~#=]{2,256}(.[a-z]{2,}\b([-a-zA-Z0-9@:%_+.~#?&//=]*))*(.com|.vn|.net|.org|.int|.edu|.gov|.mil)"
              : // "[(http(s)?):\\/\\/(www\\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)"
                //  (patternType === "text" && null) ||
                //  null
                null
          }
          autoComplete="off"
          {...props}
        />
        {errors[name] && (
          <span className="inline-block pt-2 text-sm text-arrivalhub-danger-500">
            {errors[name]?.message}
          </span>
        )}
      </div>
    </ErrorBoundary>
  );
};
/**
 *
 * @param {{
 * variant: "light" | "outline";
 * }} props Props for the component
 *
 */
export const InputPhone = ({
  name,
  placeholder,
  register,
  errors,
  variant,
  othersClass,
  requiredText,
  invalidText,
  validCondition,
  name_label,
  ...props
}) => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <div className={othersClass}>
        <input
          type="tel"
          {...register(
            name || "phone_number",
            validCondition
              ? validCondition
              : {
                  required: {
                    value: true,
                    message: requiredText || "Phone number is required",
                  },
                }
          )}
          placeholder={placeholder || "Phone"}
          className={classNames(
            className(variant, errors[name] ? errors[name] : "")
          )}
          autoComplete="off"
          {...props}
        />
        {errors[name] && (
          <span className="inline-block pt-2 text-sm text-arrivalhub-danger-500">
            {name ? errors[name]?.message : errors["phone_number"]?.message}
          </span>
        )}
      </div>
    </ErrorBoundary>
  );
};

export const InputNumber = ({
  numberValue,
  min,
  max,
  setNumberValue,
  name_label,
  ...props
}) => {
  const handleOnclickNumber = (number) => {
    setNumberValue(number);
  };
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <div className="relative flex w-28 items-center lg:mb-6 lg:w-40">
        <button
          className="absolute left-2 w-5 lg:left-5 "
          onClick={(e) => {
            if (numberValue > min) {
              handleOnclickNumber(parseInt(numberValue) - 1);
            }
            e.preventDefault();
          }}
        >
          -
        </button>
        <button
          className="absolute right-2 w-5 lg:right-5 "
          onClick={(e) => {
            if (numberValue < max) {
              handleOnclickNumber(parseInt(numberValue) + 1);
            }
            e.preventDefault();
          }}
        >
          +
        </button>
        <label className="mb-1 inline-block text-arrivalhub-gray-500">
          {name_label}
        </label>
        <input
          className="moz:appearance-none h-10 w-28 rounded-full border-zinc-200 text-center lg:w-40"
          type="text"
          value={numberValue}
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          min={min}
          onChange={(e) => {
            e.preventDefault();
            const newValue = e.target.value;
            if (newValue === "") {
              setNumberValue("");
            } else if (newValue < min) {
              setNumberValue(min);
            } else if (newValue > max) {
              setNumberValue(max);
            } else {
              setNumberValue(newValue);
            }
          }}
          onBlur={() => {
            if (numberValue < min) {
              setNumberValue(min);
            }
          }}
        />
      </div>
    </ErrorBoundary>
  );
};

export const CheckBox = ({
  as,
  name,
  text,
  placeholder,
  register,
  errors,
  requiredText,
  ...props
}) => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <div className="relative block pl-7">
        <label className="mb-1 inline-block text-arrivalhub-gray-500">
          {name_label}
        </label>
        <input
          type="checkbox"
          {...register(name, {
            // required: {
            //   value: true,
            //   message: requiredText || "This field is required",
            // },
          })}
          className="absolute left-1 top-1 block cursor-pointer rounded bg-transparent focus:shadow-none focus:outline-none focus:ring-0"
          {...props}
        />
        <div dangerouslySetInnerHTML={{ __html: text }}></div>
      </div>
      {errors[name] && (
        <span className="inline-block pt-2 text-sm text-arrivalhub-danger-500">
          {errors[name]?.message}
        </span>
      )}
    </ErrorBoundary>
  );
};
