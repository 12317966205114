import PropTypes from "prop-types";
import { useEffect } from "react";
import { useState, Children, cloneElement } from "react";
import styles from "./index.module.scss";

import { withErrorBoundary } from "react-error-boundary";
import ErrorFallback from "components/common/ErrorFallback";

const Tooltip = (props) => {
  const {
    placement,
    content,
    contentProps,
    children,
    className,
    useCloseButton,
    onStateChange,
    isOpen,
    ...otherProps
  } = props;
  const [show, setShow] = useState(isOpen);

  useEffect(() => {
    setShow(isOpen);
  }, [isOpen]);

  const handleMouseIn = () => {
    setShow(true);
  };

  const handleMouseOut = () => {
    setShow(false);
  };

  const handleClick = () => {
    // setShow(!show);
    onStateChange && onStateChange(!show);
  };

  return (
    <div
      className={`${styles.tooltip} ${className || ""}`}
      // onMouseOver={handleMouseIn}
      // onMouseLeave={handleMouseOut}
      // onClick={() => setShow(true)}
      {...otherProps}
    >
      {show && (
        <>
          <div
            className={`${styles["tooltip-content"]} ${
              contentProps?.className || ""
            }`}
            data-placement={placement}
          >
            {useCloseButton && (
              <button
                className={`${styles["tooltip-button-close"]}`}
                onClick={handleClick}
              ></button>
            )}
            {content}
          </div>
        </>
      )}

      {Children.map(children, (element, idx) => {
        return (
          <>
            {cloneElement(element, {
              onClick: handleClick,
            })}
          </>
        );
      })}
    </div>
  );
};

Tooltip.propTypes = {
  content: PropTypes.object || PropTypes.string,
  disabled: PropTypes.bool,
};

export default withErrorBoundary(Tooltip, { FallbackComponent: ErrorFallback });
